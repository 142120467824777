/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    strong: "strong",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Continuamos con el curso, a parte de la teoría que hay en esta serie de artículos voy a abrir una sección de apuntes al curso de control, la idea es poner cosillas más fuera de la explicación principal que completen al curso. Lo mejor de todo es que podemos crear un curso distribuido, si queréis hacer algún apunte vosotros, la única norma es poner como etiqueta “Apuntes al Curso de Control” y luego podemos hacer una recopilación con el nuevo gestor de enlaces de entramado, ya lo veremos, empezamos:"), "\n", React.createElement(_components.p, null, "Podemos utilizar la ingeniería de sistemas de distintas formas, en este capítulo vamos a ver las distintas estrategias que podemos seguir, entre las que se encuentra, por supuesto, el control."), "\n", React.createElement(_components.p, null, "Las estrategias son las siguientes:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Modelar:"), " Llamamos modelar a obtener la ", React.createElement(_components.strong, null, "función matemática"), " que relaciona la ", React.createElement(_components.strong, null, "salida"), " del sistema con la ", React.createElement(_components.strong, null, "entrada"), ", podemos usar variables internas o una relación directa y como hemos visto que tratamos con sistemas dinámicos, necesitaremos relacionarlo con entradas y salidas en instantes anteriores al actual."), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 300px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/2eff14e7e777b53e668c0d4517a06dc8/5a46d/Modelado1-300x124.png\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 41.139240506329116%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA0UlEQVQoz52SyQqDQBBE5/9/z4tHd0XcFTfUljcwg4kmhDQUJb1Udw2qKIpkmiYZhsFiHEed+4ZrPyAXx7GopmnkPeZ5lq7rdGPf9zeQfwq0VFmWj4W6ru01y7JoNpdnWSbbtt3m0FJ5ngtAAJB0XVf2fbdNSZJoRpAoisLOtG0rQRBou3yrqqrkOA5tk0vWdZU0TTUTMDX4ugTb5JgxdRZYyzSbASxjixpgKcxlgIXGMscAc7n69MD/BFrKcRzh1/E8T3zffwFv80uO2TAMBa0T3ztn+mZLEoUAAAAASUVORK5CYII='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Representación esquematica modelado\"\n        title=\"Representación esquematica modelado\"\n        src=\"/static/2eff14e7e777b53e668c0d4517a06dc8/5a46d/Modelado1-300x124.png\"\n        srcset=\"/static/2eff14e7e777b53e668c0d4517a06dc8/c26ae/Modelado1-300x124.png 158w,\n/static/2eff14e7e777b53e668c0d4517a06dc8/5a46d/Modelado1-300x124.png 300w\"\n        sizes=\"(max-width: 300px) 100vw, 300px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
    }
  }), "](http://litox.entramado.net/wp-content/uploads/sites/6/Modelado1.png “¿Qué función se ajusta al sistema?“)"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Simular:"), " Este es el paso inverso al anterior, una vez tenemos el modelo matemático, le aplicamos entradas a lo largo del tiempo y obtenemos las salidas para cada instante de tiempo. Este es el método que se usa para ", React.createElement(_components.strong, null, "predicción"), ". Por ejemplo, como hablábamos el último día de predicción meteorológica, esta consiste en un modelo meteorológico al que se le aplican las entradas como la temperatura actual, dirección del viento, humedad, etc y se obtiene una predicción más o menos acertada."), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 300px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/a698b207665c2fd98950edb5db3e544e/5a46d/Simulaci%C3%B3n-300x124.png\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 41.139240506329116%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA0UlEQVQoz52Q2wqDQAxE9/9/zxcfvaKI97uippyAi61tKQ0MG5KdmSQmiiKZpkn6vrcYx1Fr33D9D6jFcSymrmt5jXmepW1b/dh13Q3U3wVapiiKt42qqtR1GAZZlsXmvGmayrZtNx5aJssyAQgAiq7rWsK6rsJZrlPleW45TdNIEAS6Lrkpy1KO49A1mQSBJEn0Jegx1XUiTDHgDxy45BjYlfd9V5wrsxY9psH0zAGGpwGG4JzcfDrwP4GWcRxHb+R5nvi+/wRu80sNbhiGgtYDU5NoUYd95PcAAAAASUVORK5CYII='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Representación de una simulación\"\n        title=\"Se aplican entradas y se obtienen salidas.\"\n        src=\"/static/a698b207665c2fd98950edb5db3e544e/5a46d/Simulaci%C3%B3n-300x124.png\"\n        srcset=\"/static/a698b207665c2fd98950edb5db3e544e/c26ae/Simulaci%C3%B3n-300x124.png 158w,\n/static/a698b207665c2fd98950edb5db3e544e/5a46d/Simulaci%C3%B3n-300x124.png 300w\"\n        sizes=\"(max-width: 300px) 100vw, 300px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
    }
  })), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Controlar:"), " En este caso entra en juego un nuevo actor, ", React.createElement(_components.strong, null, "la referencia"), ". ¿Donde queremos que vaya el sistema? ¿Cómo queremos que se comporte? Ese es nuestro objetivo y se le da el nombre de referencia(r). En el caso del control queremos que el sistema siga a esa referencia lo mejor posible, entonces tendremos que crear un sistema que tenga como entrada la referencia y como salida las ", React.createElement(_components.strong, null, "acciones de control"), " que irán a parar a la entrada del sistema a controlar(u). Se entiende mejor gráficamente:"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 300px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/fd8b2f1a7166a86282a24cfc7bb3d05b/5a46d/Control-300x124.png\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 41.139240506329116%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA70lEQVQoz62QSY6DUAxEuf/1WLBjhjDPYQZHzxIoQb1sS5brq8p2+RtRFMk0TdL3vQzDoPiZ7/dbub/4cRy1F8wso2ka+Q6aEFzZdZ3s+37zbdv+8Ay8gllGVVX6OI5Da5IkKsLVPM9SlqU2Etu2SRzHtzMyyzI5z1N5Zhl1XWtjURQqtixL0jSVIAjEcRx5vV56CoNt21aMDswiDICpXHM7XJZFN0Lgdl1XPRURy3DBd4Dh0VNZjmOwOmTz98m8aaIiyPNcL7g0LLw4dPzbdTJvg63/FcwyTNOUMAzFdV3xPE/T9/2f+swnTy9/zqwPnD1lAFbSGdEAAAAASUVORK5CYII='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Esquema de un controlador unido a un sistema.\"\n        title=\"Esquema de control muy simplificado.\"\n        src=\"/static/fd8b2f1a7166a86282a24cfc7bb3d05b/5a46d/Control-300x124.png\"\n        srcset=\"/static/fd8b2f1a7166a86282a24cfc7bb3d05b/c26ae/Control-300x124.png 158w,\n/static/fd8b2f1a7166a86282a24cfc7bb3d05b/5a46d/Control-300x124.png 300w\"\n        sizes=\"(max-width: 300px) 100vw, 300px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "Pero esto no termina aquí ahora nos podemos plantear como podemos hacer el controlador, ahí empieza el verdadero control."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
